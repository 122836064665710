var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.sendLoading,
            expression: "sendLoading",
          },
        ],
        staticClass: "flex flex-col space-y-2",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.sendComment.apply(null, arguments)
          },
        },
      },
      [
        _c("base-label", { attrs: { for: "message" } }, [
          _vm._v(" Author a comment "),
        ]),
        _c("textarea-input", {
          staticClass: "shadow-none resize-none mb-4",
          attrs: {
            id: "message",
            autocomplete: "off",
            rows: 6,
            type: "text",
            placeholder: "Write an addition or comment here",
          },
          model: {
            value: _vm.message,
            callback: function ($$v) {
              _vm.message = $$v
            },
            expression: "message",
          },
        }),
        _c(
          "span",
          {
            staticClass: "inline-flex items-center space-x-2 text-sm",
            class: !_vm.signComment && _vm.message ? "visible" : "invisible",
          },
          [
            _c("fv-icon", { attrs: { icon: "warning" } }),
            _c("span", [
              _vm._v(
                "The addition will be private and NOT sent to the customer"
              ),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "flex justify-between items-center" }, [
          _c("div", { staticClass: "my-2 flex items-center max-h-full" }, [
            _c(
              "label",
              {
                staticClass: "text-sm mr-2",
                class: { "text-gray-500": _vm.signComment },
                attrs: { for: "sign" + _vm.id },
              },
              [_vm._v(" Private ")]
            ),
            _c("div", { staticClass: "form-switch max-h-full" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.signComment,
                    expression: "signComment",
                  },
                ],
                staticClass: "form-switch-checkbox",
                attrs: { id: "sign" + _vm.id, type: "checkbox", name: "sign" },
                domProps: {
                  checked: Array.isArray(_vm.signComment)
                    ? _vm._i(_vm.signComment, null) > -1
                    : _vm.signComment,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.signComment,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.signComment = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.signComment = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.signComment = $$c
                    }
                  },
                },
              }),
              _c("label", {
                staticClass: "form-switch-label display-none",
                attrs: { for: "sign" + _vm.id },
              }),
            ]),
            _c(
              "label",
              {
                staticClass: "text-sm",
                class: { "text-gray-500": !_vm.signComment },
                attrs: { for: "sign" + _vm.id },
              },
              [_vm._v(" Send ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "inline-flex space-x-2" },
            [
              _vm.editMode
                ? _c(
                    "base-button",
                    {
                      attrs: { tabindex: "0", type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.$emit("close"), _vm.setEditComment(false)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  )
                : _vm._e(),
              _c(
                "base-button",
                {
                  attrs: {
                    color: "dark",
                    tabindex: "0",
                    disabled: _vm.sendLoading,
                    type: "submit",
                  },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }