<template>
  <div class="mb-10 flex flex-col justify-between leading-6">
    <div :class="{ 'opacity-50': editComment }" class="space-y-6">
      <div v-if="formattedDate" class="flex flex-col space-y-1">
        <title-row> Signed by </title-row>
        <display-row> {{ userName }} ({{ formattedDate }}) </display-row>
      </div>

      <div v-if="appointmentType" class="flex flex-col space-y-1">
        <title-row> Case type </title-row>
        <display-row>
          {{ appointmentType.name }}
        </display-row>
      </div>

      <div v-if="journal.anamnesis" class="flex flex-col space-y-1">
        <title-row> History </title-row>
        <nl2br tag="p" class-name="pt-2" :text="journal.anamnesis" />
      </div>

      <div v-if="journal.current_status" class="flex flex-col space-y-1">
        <title-row> Current status </title-row>
        <nl2br tag="p" class-name="pt-2" :text="journal.current_status" />
      </div>

      <div v-if="isPreScreeningMeeting" class="flex flex-col space-y-1">
        <title-row> Vet approved </title-row>
        <display-row>
          {{ answered(journal.vet_approved) }}
        </display-row>
      </div>

      <div v-if="journal.diagnosis" class="flex flex-col space-y-1">
        <title-row> Differential diagnoses / assessment </title-row>
        <nl2br tag="p" class-name="pt-2" :text="journal.diagnosis" />
      </div>

      <div
        v-if="journal.diagnosis_codes && journal.diagnosis_codes.length"
        class="flex flex-col space-y-1"
      >
        <title-row> Diagnosis codes </title-row>
        <div class="prose pt-2">
          <ul class="flex flex-col space-y-1">
            <li v-for="code in journal.diagnosis_codes" :key="code.id">
              <span class="text-gray-900">{{ code.label }}</span>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="journal.treatment" class="flex flex-col space-y-1">
        <title-row> Treatment / recommendation </title-row>
        <div class="prose pt-2 text-gray-900" v-html="journal.treatment" />
      </div>

      <div v-if="referralTypeName" class="flex flex-col space-y-1">
        <title-row> Emergency level </title-row>
        <nl2br tag="p" class-name="pt-2" :text="referralTypeName" />
      </div>

      <div v-if="showPrescriptionSection" class="flex flex-col space-y-1">
        <title-row> Prescription </title-row>
        <nl2br
          v-if="prescriptionNeededText"
          tag="p"
          class-name="pt-2"
          :text="prescriptionNeededText"
        />
        <nl2br
          v-if="prescriptionInfo"
          tag="p"
          class-name="pt-2"
          :text="prescriptionInfo"
        />
      </div>

      <div
        v-if="productRecommendations && productRecommendations.length"
        class="flex flex-col space-y-1"
      >
        <title-row> Product recommendations </title-row>
        <ProductRecommendations
          class="pt-2"
          :products="productRecommendations"
          :editable="false"
        />
      </div>

      <div v-if="followUp && followUp.shouldFollowUp" class="border-t py-2">
        <title-row> Follow up </title-row>
        <div class="flex flex-col space-y-2 py-2">
          <div v-if="followUp.followUpWith">
            <p class="pt-2">With:</p>
            <nl2br
              tag="p"
              class-name="pt-2"
              :text="getFollowUpOptionName(followUp.followUpWith)"
            />
          </div>
          <div v-if="followUp.followUpAt">
            <p class="pt-2">No later than:</p>
            <nl2br
              tag="p"
              class-name="pt-2"
              :text="format(new Date(followUp.followUpAt), 'yyyy-MM-dd')"
            />
          </div>

          <div v-if="followUp.comment">
            <p class="pt-2">Comment:</p>
            <nl2br tag="p" class-name="pt-2" :text="followUp.comment" />
          </div>
        </div>
      </div>

      <div v-if="filteredAdditionalInfo" class="border-t py-2">
        <div v-for="additional in filteredAdditionalInfo" :key="additional.key">
          <div
            v-if="journal[additional.model] !== null"
            class="flex flex-col space-y-2 py-2"
          >
            <p class="pt-2">
              {{ additional.title }}
            </p>
            <p class="font-semibold">
              {{ answered(journal[additional.model]) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <comments
      v-if="journal.status && journal.comments && journal.comments.length"
      :comments="journal.comments"
      class="pt-6"
    />

    <div v-if="journal.status && !editComment && displayPostCommentSection">
      <post-comment class="pt-4" :journal-id="journal.id" />
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { mapGetters, mapActions } from 'vuex';
import Nl2br from 'vue-nl2br';
import Comments from '@/components/journal/Comments';
import PostComment from '@/components/journal/PostComment';
import ProductRecommendations from '@/components/journal/ProductRecommendations';
import { journalFollowUpOptions } from '@/config/journal-config';

export default {
  components: {
    Nl2br,
    Comments,
    PostComment,
    ProductRecommendations,
  },
  props: {
    journal: {
      type: Object,
      required: false,
      default: null,
    },
    appointmentType: {
      type: Object,
      required: false,
      default: null,
    },
    timezone: {
      type: String,
      required: false,
      default: '',
    },
    userName: {
      type: String,
      required: true,
    },

    filteredAdditionalInfo: {
      type: Array,
      required: false,
      default: null,
    },

    displayPostCommentSection: {
      type: Boolean,
      default: true,
    },

    productRecommendations: {
      type: Array,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      additionalInfo: [
        {
          title: 'Follow-up consultation',
          key: 'follow_up',
        },
        {
          title: 'Would you have gone to vet',
          key: 'would_visit_vet',
        },
        {
          title: 'Third party involvement',
          key: 'third_party',
        },
        {
          title: 'No-show / cancellation',
          key: 'no_show_up',
        },
      ],
      journalFollowUpOptions,
      format,
    };
  },
  computed: {
    ...mapGetters('appointment', {
      isPreScreeningMeeting: 'getIsPreScreeningMeeting',
    }),
    ...mapGetters('journal', {
      referralTypes: 'getReferralTypes',
      editComment: 'getEditComment',
      followUp: 'getFollowUp',
      storePrescription: 'getPrescription',
    }),
    referralTypeName() {
      if (this.journal.referral_id && this.referralTypes.length) {
        return this.referralTypes.find(
          type => type.id === this.journal.referral_id
        )?.name;
      }
      return null;
    },
    formattedDate() {
      if (this.journal.notified && this.journal.status) {
        return format(
          zonedTimeToUtc(this.journal.notified, 'Europe/Stockholm'),
          'yyyy-MM-dd HH:mm'
        );
      }
      return false;
    },
    showPrescriptionSection() {
      return (
        this.journal.prescription_needed ||
        this.journal.prescription ||
        this.storePrescription?.prescription
      );
    },
    prescriptionNeededText() {
      let text = 'Needs a follow up prescription: ';
      if (this.journal.prescription_needed) {
        return text + 'Yes';
      }
      return text + 'No';
    },
    prescriptionInfo() {
      return this.storePrescription?.prescription || this.journal.prescription;
    },
  },
  mounted() {
    this.getJournalPrescriptions();
    if (!this.referralTypes.length) {
      this.fetchReferralTypes();
    }
  },
  methods: {
    ...mapActions('journal', ['fetchReferralTypes', 'getJournalPrescriptions']),

    answered(positive) {
      return positive ? 'Yes' : 'No';
    },

    getFollowUpOptionName(id) {
      const option = this.journalFollowUpOptions.find(
        option => option.id === id
      );
      return option ? option.name : '';
    },
  },
};
</script>
