var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "flex flex-col h-full justify-center items-center" },
          [_c("base-spinner", { staticClass: "text-lg" })],
          1
        )
      : _c(
          "div",
          {
            staticClass:
              "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 text-sm w-full",
            staticStyle: { "min-height": "18px" },
          },
          _vm._l(_vm.products, function (product) {
            return _c(
              "div",
              {
                key: product.id,
                staticClass:
                  "flex flex-col relative border border-gray-100 shadow rounded p-4",
              },
              [
                _vm.editable
                  ? _c(
                      "button",
                      {
                        staticClass: "absolute top-0 right-0 p-2 text-gray-600",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("remove", product)
                          },
                        },
                      },
                      [_c("font-awesome-icon", { attrs: { icon: "times" } })],
                      1
                    )
                  : _vm._e(),
                _c("img", {
                  staticClass:
                    "w-12 h-12 sm:w-20 sm:h-20 object-contain mb-3 mx-auto",
                  attrs: { src: product.image_url },
                }),
                _c("div", { staticClass: "text-xs mb-1" }, [
                  _c("span", [_vm._v(_vm._s(product.brand_name))]),
                  _vm._v(" - "),
                  _c("span", [_vm._v(_vm._s(product.animal_types.join(", ")))]),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "font-bold hover:underline",
                    attrs: { href: product.url, target: "_blank" },
                  },
                  [_vm._v(_vm._s(product.name))]
                ),
              ]
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }