import {
  SV_COUNTRY_ID,
  UK_COUNTRY_ID,
  NO_COUNTRY_ID,
  FI_COUNTRY_ID,
  DK_COUNTRY_ID,
  DE_COUNTRY_ID,
  US_COUNTRY_ID,
} from '@/config/countries';

interface JournalValidationSettings {
  validate: boolean;
  validation?: string;
}

interface JournalTextField {
  id: string;
  model: string;
  label: string;
  validationSettings: JournalValidationSettings;
  rows: number;
  placeholder: string;
  fieldName: string;
  showOnPreScreening: boolean;
  disableForCountries?: number[];
  useEditor?: boolean;
  classList?: string;
}

interface JournalRadioButton {
  id: string;
  title: string;
  model: string;
  fieldName: string;
  required: boolean;
  showOnPreScreening: boolean;
  showOnVeterinaryConsult: boolean;
  disableForCountries: number[];
}

interface JournalCheckbox {
  id: string;
  title: string;
  model: string;
  fieldName: string;
  tooltip: string;
  required: boolean;
  showOnPreScreening: boolean;
  showOnVeterinaryConsult: boolean;
  disableForCountries: number[];
}

interface Settings {
  countryId: number;
  isPreScreeningMeeting: boolean;
  isVeterinaryConsult: boolean;
}

interface CheckboxSettings {
  countryId: number;
  isPreScreeningMeeting: boolean;
  isVeterinaryConsult: boolean;
}

const generateJournalRadioButtons = (
  settings: Settings
): JournalRadioButton[] => {
  const vetApproved: JournalRadioButton = {
    id: 'vet_approved',
    title: 'Vet approved',
    model: 'vet_approved',
    fieldName: 'vetApproved',
    required: true,
    showOnPreScreening: true,
    showOnVeterinaryConsult: false,
    disableForCountries: [SV_COUNTRY_ID],
  };

  const followUp: JournalRadioButton = {
    id: 'follow_up',
    title: 'Follow-up consultation?',
    model: 'follow_up',
    fieldName: 'followUp',
    required: true,
    showOnPreScreening: false,
    showOnVeterinaryConsult: true,
    disableForCountries: [SV_COUNTRY_ID],
  };

  const wouldVisitVet: JournalRadioButton = {
    id: 'would_visit_vet',
    model: 'would_visit_vet',
    title: 'Would you have gone to vet?',
    fieldName: 'wouldVisitVet',
    required: true,
    showOnPreScreening: false,
    showOnVeterinaryConsult: true,
    disableForCountries: [SV_COUNTRY_ID],
  };

  const thirdParty: JournalRadioButton = {
    id: 'third_party',
    model: 'third_party',
    title: 'Third party involvement?',
    fieldName: 'thirdParty',
    required: true,
    showOnPreScreening: false,
    showOnVeterinaryConsult: true,
    disableForCountries: [SV_COUNTRY_ID],
  };

  const noShowUp: JournalRadioButton = {
    id: 'no_show_up',
    model: 'no_show_up',
    title: 'No-show / cancellation?',
    fieldName: 'noShowUp',
    required: true,
    showOnPreScreening: false,
    showOnVeterinaryConsult: true,
    disableForCountries: [SV_COUNTRY_ID],
  };

  const inputs = [vetApproved, followUp, wouldVisitVet, thirdParty, noShowUp];

  const filterByCountry = (journalButton: JournalRadioButton) =>
    !journalButton.disableForCountries.includes(settings.countryId);

  const filterByPreScreening = (journalButton: JournalRadioButton) =>
    (settings.isPreScreeningMeeting && journalButton.showOnPreScreening) ===
    settings.isPreScreeningMeeting;

  const filterByVeterinaryConsult = (journalButton: JournalRadioButton) =>
    (settings.isVeterinaryConsult && journalButton.showOnVeterinaryConsult) ===
    settings.isVeterinaryConsult;

  return inputs
    .filter(filterByCountry)
    .filter(filterByPreScreening)
    .filter(filterByVeterinaryConsult);
};

const generateJournalCheckbox = (
  settings: CheckboxSettings
): JournalCheckbox[] => {
  const countriesExceptForSweden = [
    UK_COUNTRY_ID,
    NO_COUNTRY_ID,
    FI_COUNTRY_ID,
    DK_COUNTRY_ID,
    DE_COUNTRY_ID,
    US_COUNTRY_ID,
  ];

  const isFollowUp: JournalCheckbox = {
    id: 'is_follow_up',
    model: 'is_follow_up',
    title: 'Follow-up to previous consultation',
    fieldName: 'isFollowUp',
    tooltip: 'Check the box if this is a follow-up consultation.',
    required: true,
    showOnPreScreening: false,
    showOnVeterinaryConsult: true,
    disableForCountries: countriesExceptForSweden,
  };

  const prescriptionNeeded: JournalCheckbox = {
    id: 'prescription_needed',
    model: 'prescription_needed',
    title: 'Needs a follow up prescription?',
    fieldName: 'prescriptionNeeded',
    tooltip:
      'Check if the journal requires a vet to follow up with a prescription.',
    required: true,
    showOnPreScreening: false,
    showOnVeterinaryConsult: true,
    disableForCountries: [],
  };

  const inputs = [isFollowUp, prescriptionNeeded];

  const filterByCountry = (journalCheckbox: JournalCheckbox) =>
    !journalCheckbox.disableForCountries.includes(settings.countryId);

  const filterByPreScreening = journalCheckbox =>
    (settings.isPreScreeningMeeting && journalCheckbox.showOnPreScreening) ===
    settings.isPreScreeningMeeting;

  const filterByVeterinaryConsult = journalCheckbox =>
    (settings.isVeterinaryConsult &&
      journalCheckbox.showOnVeterinaryConsult) === settings.isVeterinaryConsult;

  return inputs
    .filter(filterByCountry)
    .filter(filterByPreScreening)
    .filter(filterByVeterinaryConsult);
};

const generateJournalFields = (settings: Settings): JournalTextField[] => {
  const anamnesisField: JournalTextField = {
    id: 'anamnesis',
    model: 'anamnesis',
    label: 'History',
    validationSettings: {
      validate: true,
      validation: 'required|min:8',
    },
    rows: 6,
    placeholder: 'History',
    fieldName: 'anamnesis',
    showOnPreScreening: false,
  };

  const currentStatusField: JournalTextField = {
    id: 'currentStatus',
    model: 'current_status',
    label: 'Current status',
    validationSettings: {
      validate: true,
      validation: 'required|min:8',
    },
    rows: 6,
    placeholder: 'Current status',
    fieldName: 'currentStatus',
    showOnPreScreening: true,
  };

  const diagnosisField: JournalTextField = {
    id: 'diagnosis',
    model: 'diagnosis',
    label: 'Differential diagnoses / assessment',
    validationSettings: {
      validate: false,
    },
    rows: 4,
    placeholder: 'Differential diagnoses / assessment',
    fieldName: 'diagnosis',
    showOnPreScreening: false,
  };

  const filterByPreScreening = journalTextField =>
    (settings.isPreScreeningMeeting && journalTextField.showOnPreScreening) ===
    settings.isPreScreeningMeeting;

  const inputs = [anamnesisField, currentStatusField, diagnosisField];
  return inputs.filter(filterByPreScreening);
};

const journalFollowUpOptions = [
  {
    id: 1,
    name: 'Me',
    shortName: 'Me',
  },
  {
    id: 2,
    name: 'Any veterinarian',
    shortName: 'Any veterinarian',
  },
  {
    id: 4,
    name: 'Veterinary nurse',
    shortName: 'Veterinary nurse',
  },
];

export {
  generateJournalFields,
  generateJournalRadioButtons,
  generateJournalCheckbox,
  journalFollowUpOptions,
};
