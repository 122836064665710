var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-10 flex flex-col justify-between leading-6" },
    [
      _c(
        "div",
        { staticClass: "space-y-6", class: { "opacity-50": _vm.editComment } },
        [
          _vm.formattedDate
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" Signed by ")]),
                  _c("display-row", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.userName) +
                        " (" +
                        _vm._s(_vm.formattedDate) +
                        ") "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.appointmentType
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" Case type ")]),
                  _c("display-row", [
                    _vm._v(" " + _vm._s(_vm.appointmentType.name) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.journal.anamnesis
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" History ")]),
                  _c("nl2br", {
                    attrs: {
                      tag: "p",
                      "class-name": "pt-2",
                      text: _vm.journal.anamnesis,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.journal.current_status
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" Current status ")]),
                  _c("nl2br", {
                    attrs: {
                      tag: "p",
                      "class-name": "pt-2",
                      text: _vm.journal.current_status,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isPreScreeningMeeting
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" Vet approved ")]),
                  _c("display-row", [
                    _vm._v(
                      " " + _vm._s(_vm.answered(_vm.journal.vet_approved)) + " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.journal.diagnosis
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [
                    _vm._v(" Differential diagnoses / assessment "),
                  ]),
                  _c("nl2br", {
                    attrs: {
                      tag: "p",
                      "class-name": "pt-2",
                      text: _vm.journal.diagnosis,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.journal.diagnosis_codes && _vm.journal.diagnosis_codes.length
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" Diagnosis codes ")]),
                  _c("div", { staticClass: "prose pt-2" }, [
                    _c(
                      "ul",
                      { staticClass: "flex flex-col space-y-1" },
                      _vm._l(_vm.journal.diagnosis_codes, function (code) {
                        return _c("li", { key: code.id }, [
                          _c("span", { staticClass: "text-gray-900" }, [
                            _vm._v(_vm._s(code.label)),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.journal.treatment
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" Treatment / recommendation ")]),
                  _c("div", {
                    staticClass: "prose pt-2 text-gray-900",
                    domProps: { innerHTML: _vm._s(_vm.journal.treatment) },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.referralTypeName
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" Emergency level ")]),
                  _c("nl2br", {
                    attrs: {
                      tag: "p",
                      "class-name": "pt-2",
                      text: _vm.referralTypeName,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showPrescriptionSection
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" Prescription ")]),
                  _vm.prescriptionNeededText
                    ? _c("nl2br", {
                        attrs: {
                          tag: "p",
                          "class-name": "pt-2",
                          text: _vm.prescriptionNeededText,
                        },
                      })
                    : _vm._e(),
                  _vm.prescriptionInfo
                    ? _c("nl2br", {
                        attrs: {
                          tag: "p",
                          "class-name": "pt-2",
                          text: _vm.prescriptionInfo,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.productRecommendations && _vm.productRecommendations.length
            ? _c(
                "div",
                { staticClass: "flex flex-col space-y-1" },
                [
                  _c("title-row", [_vm._v(" Product recommendations ")]),
                  _c("ProductRecommendations", {
                    staticClass: "pt-2",
                    attrs: {
                      products: _vm.productRecommendations,
                      editable: false,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.followUp && _vm.followUp.shouldFollowUp
            ? _c(
                "div",
                { staticClass: "border-t py-2" },
                [
                  _c("title-row", [_vm._v(" Follow up ")]),
                  _c("div", { staticClass: "flex flex-col space-y-2 py-2" }, [
                    _vm.followUp.followUpWith
                      ? _c(
                          "div",
                          [
                            _c("p", { staticClass: "pt-2" }, [_vm._v("With:")]),
                            _c("nl2br", {
                              attrs: {
                                tag: "p",
                                "class-name": "pt-2",
                                text: _vm.getFollowUpOptionName(
                                  _vm.followUp.followUpWith
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.followUp.followUpAt
                      ? _c(
                          "div",
                          [
                            _c("p", { staticClass: "pt-2" }, [
                              _vm._v("No later than:"),
                            ]),
                            _c("nl2br", {
                              attrs: {
                                tag: "p",
                                "class-name": "pt-2",
                                text: _vm.format(
                                  new Date(_vm.followUp.followUpAt),
                                  "yyyy-MM-dd"
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.followUp.comment
                      ? _c(
                          "div",
                          [
                            _c("p", { staticClass: "pt-2" }, [
                              _vm._v("Comment:"),
                            ]),
                            _c("nl2br", {
                              attrs: {
                                tag: "p",
                                "class-name": "pt-2",
                                text: _vm.followUp.comment,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.filteredAdditionalInfo
            ? _c(
                "div",
                { staticClass: "border-t py-2" },
                _vm._l(_vm.filteredAdditionalInfo, function (additional) {
                  return _c("div", { key: additional.key }, [
                    _vm.journal[additional.model] !== null
                      ? _c(
                          "div",
                          { staticClass: "flex flex-col space-y-2 py-2" },
                          [
                            _c("p", { staticClass: "pt-2" }, [
                              _vm._v(" " + _vm._s(additional.title) + " "),
                            ]),
                            _c("p", { staticClass: "font-semibold" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.answered(_vm.journal[additional.model])
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _vm.journal.status && _vm.journal.comments && _vm.journal.comments.length
        ? _c("comments", {
            staticClass: "pt-6",
            attrs: { comments: _vm.journal.comments },
          })
        : _vm._e(),
      _vm.journal.status && !_vm.editComment && _vm.displayPostCommentSection
        ? _c(
            "div",
            [
              _c("post-comment", {
                staticClass: "pt-4",
                attrs: { "journal-id": _vm.journal.id },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }