<template>
  <div class="py-3">
    <div v-if="edit && !comment.status">
      <post-comment
        :comment="comment"
        :edit-mode="true"
        @close="edit = false"
      />
    </div>
    <div v-else>
      <div :class="{ 'opacity-50': editComment }">
        <div class="flex">
          <div class="flex flex-col items-center justify-between">
            <user-avatar :avatar="comment.user.avatarUrl || ''" :size="2" />
            <slot name="icon"></slot>
          </div>
          <div class="ml-3 w-full">
            <div class="flex flex-col justify-between font-bold">
              <p>
                {{ formatAuthor() }}
                <span class="font-medium text-gray-500">
                  - {{ formattedDate }}</span
                >
              </p>
              <div class="flex items-center">
                <span class="mr-2 font-medium">{{
                  comment.status ? 'Sent' : 'Private'
                }}</span>
                <fv-icon
                  v-if="comment.status"
                  icon="check-mark"
                  class="text-fv-green"
                />
                <text-button
                  v-if="!comment.status && !selectMode"
                  class="flex h-6 w-6 items-center justify-center rounded-full hover:bg-opacity-50"
                  :class="
                    editComment
                      ? 'cursor-default bg-opacity-50'
                      : 'cursor-pointer'
                  "
                  @click="setEdit"
                >
                  <fv-icon icon="edit" class="text-xs" />
                </text-button>
              </div>
            </div>
            <div>
              <p class="comment pt-2">
                {{ comment.comment }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import UserAvatar from '@/components/user/UserAvatar';
import PostComment from '@/components/journal/PostComment';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export default {
  name: 'JournalComment',
  components: {
    UserAvatar,
    PostComment,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    selectMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
    };
  },

  computed: {
    ...mapGetters('appointment', {
      timezone: 'getTimezone',
    }),
    ...mapGetters('journal', {
      editComment: 'getEditComment',
    }),
    formattedDate() {
      return format(
        zonedTimeToUtc(this.comment.updated_at, 'Europe/Stockholm'),
        "MMM dd yyyy 'at' HH:mm"
      );
    },
  },
  beforeDestroy() {
    this.setEditComment(false);
  },
  methods: {
    ...mapMutations('journal', ['setEditComment']),
    setEdit() {
      if (this.editComment) return;
      this.edit = !this.edit;
      this.setEditComment(true);
    },
    formatAuthor() {
      if (this.comment.user.job_title) {
        return `(${this.comment.user.job_title.name}) ${this.comment.user.first_name} ${this.comment.user.last_name}`;
      }

      return `${this.comment.user.first_name} ${this.comment.user.last_name}`;
    },
  },
};
</script>
<style scoped lang="postcss">
.comment {
  max-width: fit-content;
}
</style>
