<template>
  <div class="w-full">
    <div
      v-if="loading"
      class="flex flex-col h-full justify-center items-center"
    >
      <base-spinner class="text-lg" />
    </div>
    <div
      v-else
      style="min-height: 18px"
      class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3 text-sm w-full"
    >
      <div
        v-for="product in products"
        :key="product.id"
        class="flex flex-col relative border border-gray-100 shadow rounded p-4"
      >
        <button
          v-if="editable"
          class="absolute top-0 right-0 p-2 text-gray-600"
          @click="$emit('remove', product)"
        >
          <font-awesome-icon
            icon="times"
          />
        </button>
        <img
          :src="product.image_url"
          class="w-12 h-12 sm:w-20 sm:h-20 object-contain mb-3 mx-auto"
        >
        <div class="text-xs mb-1">
          <span>{{ product.brand_name }}</span> -
          <span>{{ product.animal_types.join(', ') }}</span>
        </div>
        <a
          :href="product.url"
          class="font-bold hover:underline"
          target="_blank"
        >{{ product.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
