var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: "A " + _vm.type + " consultation",
          expression: "'A ' + type + ' consultation'",
        },
      ],
      staticClass:
        "rounded-full\n              bg-gray-300 bg-opacity-20 text-primary-darker\n            w-7 h-7 flex justify-center items-center text-xs",
    },
    [_c("fv-icon", { staticClass: "w-5 h-5", attrs: { icon: _vm.iconName } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }