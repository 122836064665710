<template>
  <span
    v-tooltip="'A ' + type + ' consultation'"
    class="rounded-full
                bg-gray-300 bg-opacity-20 text-primary-darker
              w-7 h-7 flex justify-center items-center text-xs"
  >
    <fv-icon
      class="w-5 h-5"
      :icon="iconName"
    />
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconName() {
      if (this.type === 'chat') {
        return 'chat';
      }
      if (this.type === 'video') {
        return 'video';
      }
      if (this.type === 'question') {
        return 'question';
      }
      return 'video';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
