<template>
  <div>
    <form
      v-loading="sendLoading"
      class="flex flex-col space-y-2"
      @submit.prevent="sendComment"
    >
      <base-label for="message">
        Author a comment
      </base-label>
      <textarea-input
        id="message"
        v-model="message"
        autocomplete="off"
        :rows="6"
        class="shadow-none resize-none mb-4"
        type="text"
        placeholder="Write an addition or comment here"
      />
      <span
        class="inline-flex items-center space-x-2 text-sm"
        :class="!signComment && message ? 'visible' : 'invisible'"
      >
        <fv-icon
          icon="warning"
        />
        <span>The addition will be private and NOT sent to the customer</span>
      </span>
      <div class="flex justify-between items-center">
        <div class="my-2 flex items-center max-h-full">
          <label
            class="text-sm mr-2"
            :class="{'text-gray-500' : signComment}"
            :for="'sign'+ id"
          >
            Private
          </label>
          <div class="form-switch max-h-full">
            <input
              :id="'sign' + id"
              v-model="signComment"
              type="checkbox"
              name="sign"
              class="form-switch-checkbox"
            >
            <label
              class="form-switch-label display-none"
              :for="'sign'+ id"
            />
          </div>
          <label
            class="text-sm"
            :for="'sign'+ id"
            :class="{'text-gray-500' : !signComment}"
          >
            Send
          </label>
        </div>


        <div class="inline-flex space-x-2">
          <base-button
            v-if="editMode"
            tabindex="0"
            type="button"
            @click="$emit('close'), setEditComment(false)"
          >
            Cancel
          </base-button>
          <base-button
            color="dark"
            tabindex="0"
            :disabled="sendLoading"
            type="submit"
          >
            Save
          </base-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  props: {
    journalId: {
      type: Number,
      required: false,
      default: null,
    },
    comment: {
      type: Object,
      required: false,
      default: null,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sendLoading: false,
      message: '',
      signComment: false,
      success: false,
      id: 1,
      error: null,
    };
  },
  mounted() {
    if (this.editMode) {
      this.message = this.comment.comment;
      this.signComment = this.comment.status;
      this.id = this.comment.id;
    }
    if (this.journalId) {
      this.id = this.journalId;
    }
  },
  methods: {
    ...mapActions('journal', ['sendJournalComment', 'editJournalComment']),
    ...mapMutations('journal', ['setEditComment']),

    async sendComment() {
      if (this.editMode) {
        if (
          !this.message ||
          (this.message === this.comment.comment &&
            this.signComment === this.comment.status)
        ) {
          this.$emit('close');
          return;
        }
      } else if (!this.message) return;

      this.sendLoading = true;

      const data = {
        comment: this.message,
        status: this.signComment,
      };

      if (!this.editMode) {
        data.journal_id = this.journalId;
        const res = await this.sendJournalComment(data).catch(e => {
          this.error = e;
          console.log(e);
        });
        if (res) {
          this.success = true;
          this.message = '';
        }
      } else {
        const res = await this.editJournalComment({
          id: this.comment.id,
          data,
        }).catch(e => {
          this.error = e;
          console.log(e);
        });
        if (res) {
          this.success = true;
          this.$emit('close');
          this.setEditComment(false);
        }
      }
      if (this.success) {
        this.sendLoading = false;
        const text = this.signComment
          ? 'Additional comment was sent to customer'
          : 'Addition saved';

        this.$notify({
          group: 'primary',
          title: 'Comment saved!',
          text,
        });
        this.signComment = false;
      } else {
        this.sendLoading = false;
        this.$notify({
          group: 'error',
          title: 'Something went wrong',
          text: this.error.message,
        });
      }
    },
  },
};
</script>
