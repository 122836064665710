<template>
  <div>
    <title-row class="mb-4" :class="{ 'opacity-50': editComment }">
      Additional comments
    </title-row>

    <journal-comment
      v-for="(comment, i) in sortedComments"
      :key="comment.id"
      :comment="comment"
      :comments="sortedComments"
      class="block w-full rounded border bg-white px-3 shadow-sm"
      :class="i === sortedComments.length - 1 ? 'mb-0' : 'mb-2'"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Comment from '@/components/journal/Comment';

export default {
  name: 'Comments',
  components: {
    'journal-comment': Comment,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('journal', {
      editComment: 'getEditComment',
    }),
    sortedComments() {
      if (!this.comments?.length) return [];
      const commentsCopy = this.comments.slice();
      return commentsCopy.sort((a, b) => {
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);
        return dateB - dateA;
      });
    },
  },
};
</script>
