var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "title-row",
        { staticClass: "mb-4", class: { "opacity-50": _vm.editComment } },
        [_vm._v(" Additional comments ")]
      ),
      _vm._l(_vm.sortedComments, function (comment, i) {
        return _c("journal-comment", {
          key: comment.id,
          staticClass: "block w-full rounded border bg-white px-3 shadow-sm",
          class: i === _vm.sortedComments.length - 1 ? "mb-0" : "mb-2",
          attrs: { comment: comment, comments: _vm.sortedComments },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }